/* eslint-disable */
import type { firebase } from '../../firebase/firestore';

/**
 * @minItems 1
 */
export type IConfigurationLeadsTemplateFiles = [IConfigurationLeadsTemplateFile, ...IConfigurationLeadsTemplateFile[]];

export interface IConfiguration {
  createdAt: firebase.firestore.Timestamp;
  /**
   * Polymorphic Configuration Data
   */
  data: IConfigurationLeadsLabel | IConfigurationLeadsStage | IConfigurationLeadsStatus | IConfigurationLeadsTemplate;
  /**
   * If set, should not be offered as an option.
   */
  deletedAt?: firebase.firestore.Timestamp;
  id: string;
  /**
   * If set, should not be editable or deletable.
   */
  locked?: boolean;
  /**
   * Sorting Order
   */
  order?: number;
  updatedAt?: firebase.firestore.Timestamp;
}
export interface IConfigurationLeadsLabel {
  /**
   * Color Scheme (Hex Color)
   */
  color?: string;
  /**
   * Emoji
   */
  emoji?: string;
  namespace: 'Leads';
  scope: 'Label';
  /**
   * User-Defined Value
   */
  value: string;
}
export interface IConfigurationLeadsStage {
  /**
   * Color Scheme (Hex Color)
   */
  color?: string;
  /**
   * Emoji
   */
  emoji?: string;
  namespace: 'Leads';
  scope: 'Stage';
  /**
   * User-Defined Value
   */
  value: string;
  visibility: {
    /**
     * Whether the stage is visible in the pipeline view.
     */
    pipeline: boolean;
  };
  /**
   * Weight Multiplier
   */
  weight?: number;
}
export interface IConfigurationLeadsStatus {
  namespace: 'Leads';
  scope: 'Status';
  /**
   * User-Defined Value
   */
  value: string;
}
export interface IConfigurationLeadsTemplate {
  /**
   * ISO 8601 Period
   */
  delay?: string;
  files?: IConfigurationLeadsTemplateFiles;
  namespace: 'Leads';
  scope: 'Template';
  /**
   * User-Defined Title
   */
  title: string;
  /**
   * User-Defined Value
   */
  value: string;
}
export interface IConfigurationLeadsTemplateFile {
  attachmentToken: string;
  filename: string;
  path: string;
  size?: number;
}

/**
 * Lead Default Label ID
 *
 * This interface was referenced by `IConfiguration`'s JSON-Schema
 * via the `definition` "defaultLeadLabelId".
 */
export enum ILeadDefaultLabelId {
  HotLead = '1ab3c9d2-0000-4000-8000-000000000001',
}
/**
 * Lead Default Label Value
 *
 * This interface was referenced by `IConfiguration`'s JSON-Schema
 * via the `definition` "defaultLeadLabelValue".
 */
export enum ILeadDefaultLabelValue {
  HotLead = '~Hot Lead~',
}
/**
 * Lead Default Stage ID
 *
 * This interface was referenced by `IConfiguration`'s JSON-Schema
 * via the `definition` "defaultLeadStageId".
 */
export enum ILeadDefaultStageId {
  Invitation = '5cf3f2b0-0000-4000-8000-000000000000',
  Lead = '5cf3f2b0-0000-4000-8000-000000000001',
  QualifiedLead = '5cf3f2b0-0000-4000-8000-000000000002',
  ViewingMeetingScheduled = '5cf3f2b0-0000-4000-8000-000000000003',
  DecisionPending = '5cf3f2b0-0000-4000-8000-000000000004',
  DateOnHold = '5cf3f2b0-0000-4000-8000-000000000005',
  Booked = '5cf3f2b0-0000-4000-8000-000000000006',
  Completed = '5cf3f2b0-0000-4000-8000-000000000007',
  Lost = '5cf3f2b0-0000-4000-8000-000000000008',
}
/**
 * Lead Default Stage Value
 *
 * This interface was referenced by `IConfiguration`'s JSON-Schema
 * via the `definition` "defaultLeadStageValue".
 */
export enum ILeadDefaultStageValue {
  Invitation = '~Invitation~',
  Lead = '~Lead~',
  QualifiedLead = '~Qualified Lead~',
  ViewingMeetingScheduled = '~Viewing/Meeting Scheduled~',
  DecisionPending = '~Decision Pending~',
  DateOnHold = '~Date on Hold~',
  Booked = '~Booked~',
  Completed = '~Completed~',
  Lost = '~Lost~',
}
/**
 * Lead Default Status Id
 *
 * This interface was referenced by `IConfiguration`'s JSON-Schema
 * via the `definition` "defaultLeadStatusId".
 */
export enum ILeadDefaultStatusId {
  Contacted = '7bb0ddf9-0000-4000-8000-000000000001',
  NoContactMade = '7bb0ddf9-0000-4000-8000-000000000002',
  InviteSent = '7bb0ddf9-0000-4000-8000-000000000003',
  CallScheduled = '7bb0ddf9-0000-4000-8000-000000000004',
  FollowedUp = '7bb0ddf9-0000-4000-8000-000000000005',
  Chased = '7bb0ddf9-0000-4000-8000-000000000006',
  ActionNeeded = '7bb0ddf9-0000-4000-8000-000000000007',
}
/**
 * Lead Default Status Value
 *
 * This interface was referenced by `IConfiguration`'s JSON-Schema
 * via the `definition` "defaultLeadStatusValue".
 */
export enum ILeadDefaultStatusValue {
  Contacted = '~Contacted~',
  NoContactMade = '~No Contact Made~',
  InviteSent = '~Invite Sent~',
  CallScheduled = '~Call Scheduled~',
  FollowedUp = '~Followed Up~',
  Chased = '~Chased~',
  ActionNeeded = '~Action Needed~',
}
